import React from 'react'

import styled from 'styled-components'
import { useRouteMatch } from 'react-router-dom'
import StyledLinkButton from '../StyledLinkButton'

const Wrapper = styled.div`
    border: 1px solid grey;
    padding: 10px;
    height: fit-content;
    margin: 16px 0;
`

const NoteSimulationHandler = (props) => {
    let {url} = useRouteMatch()
    if (url.slice(-1) !== '/') {
        url += '/'
    }
    return(
        <Wrapper>
            <StyledLinkButton to={`${url}simulation/`}>simulation</StyledLinkButton>
        </Wrapper>      
    )
}

export default NoteSimulationHandler