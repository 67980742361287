const { Link } = require("react-router-dom");
const { default: styled } = require("styled-components");


const StyledLinkButton = styled(Link)`
    display: inline-block;
    padding: 8px 16px;
    font-size: 0.9em;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: #1a73e8;
    color: white;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    margin: 5px 0px;
    border: 1px solid #1a73e8;
    transition: .3s;
    border-radius: 3px;
`

export default StyledLinkButton