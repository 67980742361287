import { useEffect } from "react"
import { useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import { usePeriode } from "../services/periodes/periodes"
import { note } from "../services/students"


const NoteDelete = () => {
    const history = useHistory()
    const periode = usePeriode()

    const params = useParams()

    const onClose = useCallback(() => {
        history.push('../../')
    }, [history])

    useEffect(() => {
        if (periode) {
            const m = periode.getMatiere(params.codeMatiere)
            if (m) {
                const noteId = params.noteId
                if (!isNaN(noteId) && noteId >= 0 && noteId < m.notes.length) {
                    new note(m.notes[noteId]).delete()
                    onClose()
                }
                else {
                    onClose()
                }
            }
            else {
                onClose()
            }
        }
    }, [periode, onClose, params])

    return null
}




export default NoteDelete