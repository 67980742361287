import React from 'react'
import NavPeriode from '../../components/NavPeriode'
import Moyenne from '../../components/Moyenne'
import TabNotes from '../../components/TabNotes'
import NoteSimulationHandler from '../../components/NoteSimulationHandler'
import './NotesScene.css'
import { useRouteMatch, Switch, Route, Redirect, } from 'react-router-dom'
import { useStudent } from '../../services/periodes/periodes'
import { NoteSimulationModal } from '../../components/Modals/NoteSimulationModal'
import { NoteInfo } from '../../components/Modals/NoteInfo'
import { NoteEditModal } from '../../components/Modals/NoteEditModal'
import NoteDelete from '../../components/NoteDelete'
import { StyledA } from '../../components/styled/StyledA'
import styled from 'styled-components'


const Reload = styled(StyledA)`
    color: grey;
    font-size: 0.8em;
`

export const NotesScenes = props => {
    const { path, url } = useRouteMatch()
    const student = useStudent()

    return (
        <div>
            <NavPeriode />


            <Switch>
                <Route path={`${path}/:periodeId`}>
                    <div className="NotesScene-header">
                        <Moyenne /> 
                        <NoteSimulationHandler/>

                    </div>
                    <Reload href="#" onClick={() => {window.location.reload()}}>Recharger les notes</Reload>
                    <TabNotes />
                    <Route path={`${path}/:periodeId/simulation/`}>           
                        <NoteSimulationModal visible={true}/>
                    </Route>
                    <Route path={`${path}/:periodeId/info/:codeMatiere/:noteId/`}>           
                        <NoteInfo/>
                    </Route>
                    <Route path={`${path}/:periodeId/edit/:codeMatiere/:noteId/`}>           
                        <NoteEditModal visible={true}/>
                    </Route>
                    <Route path={`${path}/:periodeId/delete/:codeMatiere/:noteId/`}>           
                        <NoteDelete />
                    </Route>
                </Route>
                <Route >
                    <Redirect to={(student && student.periodes.length >= 1) ? `${url}/${student.periodes[0].idPeriode}/` : url} />
                </Route>
            </Switch>
        </div>
    )
}





export default NotesScenes