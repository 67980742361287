import React from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import styled from 'styled-components'

const NavPeriodeLi = styled.li`
    list-style: none;
    font-size: 1.3em;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        font-size: 1.2em;
        margin: 3px;
    }
`

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: black;

    &:visited {
        all: inherit;
    }

    &.selected {
        font-weight: bold;
    }

    @media (max-width: 768px) {  
        margin: auto 10px;

    }
`

export const NavPeriodeElem = (props) => {
    const {url} = useRouteMatch()

    return(
        <NavPeriodeLi><StyledNavLink activeClassName="selected" to={`${url}/${props.periode.id}/`}>{props.periode.name}</StyledNavLink></NavPeriodeLi>
        
    )
}


export default NavPeriodeElem