import React, { useMemo } from 'react'
import styled from 'styled-components'
import { matiere } from '../../services/students'
import { MobileTabNotesLine } from './MobileTabNotesLine'


const Wrapper = styled.div`
    width: 100%;
    border: 1px solid grey;
`


export const MobileTabNotesLines = props => {
    const periode = props.periode

    const lines = useMemo(() => {
        if (periode) {
            return periode.matieres.map((m) => {
                const matiereG = new matiere(m)
                let key = matiereG.codeMatiere
                if (key === "") {
                    key = matiereG.discipline
                }
                return <MobileTabNotesLine key={key} matiere={matiereG} />
            })
        }
    }, [periode])

    return (
    <Wrapper>
        {lines}
    </Wrapper>)
}