import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { note as gNote } from '../../services/students'
import { usePeriode } from '../../services/periodes/periodes'
import { Modal } from './Modal'


export const NoteEditModal = props => {
    const periode = usePeriode()
    const history = useHistory()
    const params = useParams()


    const onClose = useCallback(() => {
        history.push('../../')
    }, [history])


    const note = useMemo(() => {
        if (periode) {
            const m = periode.getMatiere(params.codeMatiere)
            if (m) {
                const noteId = params.noteId
                if (!isNaN(noteId) && noteId >= 0 && noteId < m.notes.length) {
                    return new gNote(m.notes[noteId])
                }
                else {
                    onClose()
                }
            }
            else {
                onClose()
            }
        }
        return {}
    }, [periode, onClose, params])



    const [value, setValue] = useState(note.valeur ? note.valeur : null)

    useEffect(() => {
        if (note.valeur) {
            setValue(note.valeur)
        }
    }, [note])

    const displayValue = useMemo(() => {
        if (!value) {
            return ('')
        }
        else {
            return String(value).replace('.', ',')
        }
    }, [value])

    const changeValue = useCallback((e) => {
        let v = e.target.value.replace(',', '.')
        setValue(v)

    }, [])

    const [coef, setCoef] = useState(note.coef ? note.coef : null)

    useEffect(() => {
        if (note.coef) {
            setCoef(note.coef)
        }
    }, [note])

    const displayCoef = useMemo(() => {
        if (!coef) {
            return ('')
        }
        else {
            return String(coef).replace('.', ',')
        }
    }, [coef])

    const changeCoef = useCallback((e) => {
        let v = e.target.value.replace(',', '.')
        setCoef(v)

    }, [])



    const onSubmit = (e) => {
        e.preventDefault()
        const v = Number(value)
        const c = Number(coef)

        if (v <= note.noteSur && c >= 0) {
            note.setValeur(v)
            note.setCoef(c)
            onClose()
        }
    }




    return (
        <Modal visible={props.visible} onClose={onClose}>
            <form className="dialog-content" onSubmit={onSubmit}>
                <h1>simulation notes</h1>
                <p>{null}</p>
                <select disabled>
                    <option>{note.matiere && note.matiere.periode ? note.matiere.periode.name : null}</option>
                </select>
                <select disabled>
                    <option>{note.matiere ? note.matiere.discipline : null}</option>
                </select>
                <input type="text" name="valeur" id="valeur" placeholder="20" value={displayValue} onChange={changeValue}></input><br />
                <input type="text" name="coef" id="coef" placeholder="1" value={displayCoef} onChange={changeCoef}></input><br />
                <input type="submit" id="connect" value="valider"></input>
            </form>
        </Modal>

    )



}

export default NoteEditModal