import React, { useMemo } from 'react'
import { useContext, useCallback, useState, useEffect } from "react"
import SessionContextLink from "../SessionContext"
import LoginDialog from '../../components/dialog/LoginDialog'
import {students as gStudents} from '../students'


export const useSession = () => {
    const sessionContext = useContext(SessionContextLink)

    const sessionTemp = sessionContext.session
    useEffect(() => {

        if (!sessionTemp.onUpdate  && !sessionTemp.token) {
            sessionTemp.requestUpdate()

        }


    })

        return sessionTemp

}

export const SessionProvider = (props) => {
    const [sessionMemory, setSessionMemory] = useState({})
    const [students, setStudents] = useState(gStudents.createStudents())
    const [modal, setModal] = useState(null)
    const [onUpdate, setOnUpdate] = useState(false)
    //const [cookies, setCookie, removeCookie] = useCookies(['session', 'token']);
    
    const hideLoginModal = useCallback(() => {
        setModal(null)
        setOnUpdate(false)
    }, [setModal, setOnUpdate])


    const setSession = useCallback((data) => {
        //setCookie('session', {eleves: data.eleves}, {path: '/'})
        //setCookie('token', data.token, {path: '/'})
        sessionStorage.setItem('session', JSON.stringify({eleves: data.eleves}));
        sessionStorage.setItem('token', data.token);


        //temporaire !!!!!!!!!
        
        if (/*cookies.session && cookies.token*/sessionStorage.getItem('session') && sessionStorage.getItem('token')) {
            let session = JSON.parse(sessionStorage.getItem('session'))
            let token = sessionStorage.getItem('token')
            if (token) {
                setSessionMemory({
                    token: token,
                    eleves: session.eleves
                })
                hideLoginModal()
            }
        }


    }, [hideLoginModal])

    const genNote = useCallback(() => {
        setSessionMemory({...sessionMemory, noteGenerated: true})
    }, [sessionMemory])

    

    const showLoginModal = useCallback(() => {
        setModal(<LoginDialog visible={true} onSubmit={(data) => {
             setSession(data)
             hideLoginModal()
            }
            } />)
        setOnUpdate(true)
    }, [setSession, setOnUpdate, setModal, hideLoginModal])

    

    


    const checkSession = useCallback(() => {
        if (!sessionMemory.token ) {
            //loginModal
            //appel setSession
            if (!onUpdate) {
                 showLoginModal()
            }

        }
        else {
            hideLoginModal()
        }
    }, [onUpdate, sessionMemory, showLoginModal, hideLoginModal])

    const requestUpdate = useCallback(() => {
        checkSession()
    }, [checkSession])

    const clearSession = useCallback(() => {
        setSessionMemory({})
        sessionStorage.removeItem('session')
        sessionStorage.removeItem('token')
        window.location.reload()
    }, [])


    //cookie read
    useEffect(() => {
        if (/*cookies.session && cookies.token*/sessionStorage.getItem('session') && sessionStorage.getItem('token')) {
            let session = JSON.parse(sessionStorage.getItem('session'))
            let token = sessionStorage.getItem('token')
            if (token) {
                setSessionMemory({
                    token: token,
                    eleves: session.eleves
                })
                hideLoginModal()
            }
        }
    }, [ hideLoginModal ])


    const ProviderValue = useMemo(() => {return { session: {...sessionMemory, clear: clearSession, requestUpdate: requestUpdate, onUpdate: onUpdate, setStudents: setStudents, students: new gStudents(students, setStudents), genNote: genNote}}}, [sessionMemory, students, clearSession, genNote, onUpdate, requestUpdate])
    return (
        <div>
            {modal}
            <SessionContextLink.Provider value={ProviderValue}>
                {props.children}
            </SessionContextLink.Provider>
        </div>
    )
}






