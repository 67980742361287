import styled from 'styled-components'

export const CenterC = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`

export const CenterE = styled.div`
    width: auto;
    margin: 5px;
`