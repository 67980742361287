import styled from "styled-components";


export const BottomText = styled.p`
    bottom: 1px;
    position: absolute;
    color: grey;

    @media screen and (max-width: 800px) {

        font-size: 0.7em;
    }
`