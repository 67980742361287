import { useEffect, useState } from "react";
import { SERVER_URL } from "../constants";
import { useSession } from "../session";
import { useHistory, useParams } from "react-router-dom";
import { student, periode, matiere, note } from '../students'


export const useStudents = () => {
    const session = useSession()
    useEffect(() => {
        //console.log([...session.students.students])
        if (session.token && !session.noteGenerated && !window.noteGenerated) {
            session.genNote()
            window.noteGenerated = true
            session.eleves.forEach(eleve => {
                if (!session.students.getStudent(eleve.id)) {
                    const params = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ token: session.token })
                    }
                    fetch(`${SERVER_URL}notes/${eleve.id}`, params)
                    .then(d => d.json())
                    .then((data) => {
                        if (data.code !== 200) {
                            session.clear()
                        }
                        else {
                            parser({ code: 200, idStudent: eleve.id, data: data.data, session: session })
                        }
                        
                    })
                }
            });
            
        }
    }, [session])
    return session.students
}

export const useStudent = () => {
    const students = useStudents()
    const params = useParams()
    const history = useHistory()
    const session = useSession()
    const [student, setStudent] = useState(undefined)
    useEffect(() => {
        let s = students.getStudent(params.idStudent)
        if (s) {
            setStudent(s)
        }
        else if (session.eleves && !session.eleves.find(e => Number(e.id) === Number(params.idStudent))) {
            history.push('/')
        }
    }, [students, params.idStudent, session.eleves, history])
    
    return student
    
}

export const usePeriode = () => {
    const student = useStudent()
    const params = useParams()
    const history = useHistory()
    const [p, setP] = useState(undefined)
    
    useEffect(() => {
        if (student) {
            let r = student.getPeriode(params.periodeId)
            if (r) {
                setP(r)
            }
            else if (student.periodes.length >= 1) {
                history.push('/')
            }
        }
    }, [student, params.periodeId, history])
    
    return p
}

const parser = d => {
    
    const studentM = new student(student.createStudent(d.idStudent, d.session.students))
    let periodes_s = d.data.periodes
    periodes_s.forEach(p => {
        if (p.idPeriode !== 'A999Z' && p.idPeriode[0] === "A") {
            let pt = new periode(periode.createPeriode(p.idPeriode, p.periode, studentM))
            if (pt.id.substring(4, 5) === 'X') {
                pt.linkTo(studentM.getPeriode(pt.id.substring(0, 4)))
            }
            parseMatieres(pt, p.ensembleMatieres.disciplines)
        }
    });
    let notes = d.data.notes
    var Nparser = (x) => { 
        if (typeof(x) === 'undefined') {
            return 0
        } else {
            return (parseFloat(x.replace(',', '.'))) 
        }}
        notes.map((n) => {
            const m = studentM.getPeriode(n.codePeriode).getMatiere(n.codeMatiere)
            if (m) {
                note.createNote(n.devoir, Nparser(n.coef), Nparser(n.noteSur), Nparser(n.valeur), Nparser(n.moyenneClasse), Nparser(n.minClasse), Nparser(n.maxClasse), n.nonSignificatif, m, new Date(n.date))
            }
            return undefined
        })
    }
    
    
    export function parseMatieres(periode, disciplines) {
        disciplines.map((m) => matiere.createMatiere(m.codeMatiere, m.discipline, m.coef, m.option, m.rang, periode))
    }
    