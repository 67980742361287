import React from 'react'
import styled from 'styled-components'
import { NoteList } from './NoteList'



const StyledLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const StyledMatiere = styled.p`
    text-align: center;
    background-color: #1a73e8;
    color: white;
    padding: 7px 0px;
`


export const MobileTabNotesLine = props => {
    const matiere = props.matiere
    const moyenne = matiere.moyenne().toFixed(2)
    return (
        <StyledLine>
            <StyledMatiere>{matiere.discipline} ({!isNaN(moyenne) ? moyenne : '-'}) <br /> rang: {matiere.rang}</StyledMatiere>
            <NoteList list={matiere.getNotes()} />
        </StyledLine>)
}