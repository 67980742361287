import React from 'react'
import { useSession } from './services/session'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Header from './scenes/Header'
import NotesScene from './scenes/NotesScene'


export const AppRouter = props => {
    const session = useSession()

    return(
        <Router>
                        <Header />
                        <Switch>
                            <Route path='/:idStudent' render={(props) => {
                                return (<NotesScene {...props} />)
                            }} ></Route>

                            <Route path='/' render={(props) => {
                                return (session.eleves ? <Redirect to={`/${session.eleves[0].id}`} /> : null   
                                )}}>                
                            </Route>

                        </Switch>

                        {/*<Footer />*/}
                        
                    </Router>
    )
}