import React, { useMemo } from 'react'

import NavPeriodeElem from "./NavPeriodeElem";
import { useStudent } from '../../services/periodes/periodes';
import styled from 'styled-components';
import { periode } from '../../services/students';

const NavPeriodeWrapper = styled.nav`
    margin: 0px 0;
`

const NavPeriodeUl = styled.ul`
    display: flex;
    justify-content: space-around;
    border: 1px solid grey;
    padding: 10px 0px;

    @media (max-width: 768px) {  
        flex-wrap: wrap;     
        justify-content: center;
        
    }
`


export const NavPeriode = (props) => {
    const student = useStudent()

    const listePeriode = useMemo(() => {
        if (student && student.periodes) {
            return (student.periodes.map(ps => {
                const p = new periode(ps)
                return <NavPeriodeElem key={p.id} periode={p}></NavPeriodeElem>
            }))
        }

    }, [student])

    return (
        <NavPeriodeWrapper>
            <NavPeriodeUl>
                {listePeriode}
            </NavPeriodeUl>
        </NavPeriodeWrapper>
    )
}

export default NavPeriode