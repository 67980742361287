import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { BottomText } from '../styled/BottomText'



const ModalWrapper = styled.aside`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.background ? props.background : '#00000075'};
    z-index: 289;
`

const Content = styled.div`

    width: 25%;
    z-index: 9999;
    min-width: 350px;
    text-align: center;
    display:flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 20px;


    & * {
        width: 100%;
        font-size: ${props => props.theme.medium}
    }

    & input, select {
        background-color: white;
        padding: 12px 20px;
        margin: 8px 0px;
        text-decoration: none;
        border: 1px solid #1a73e8;
        border-radius: 4px;
        appearance: none;
    }

    & input[type=submit] {
        background-color: #1a73e8;
        color: ${props => props.theme.bg};
        font-size: ${props => props.theme.medium};
        appearance: none;
    }

    & h1 {
        font-size: ${props => props.theme.large};
        margin: 10px 0px;
    }


`


export const Modal = (props) => {

    const onClose = props.onClose
    const closable = typeof (props.closable) === 'undefined' ? true : props.closable

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Esc' || e.key === 'Escape') {
            onClose()
        }
    }, [onClose])
    //setVisible(props.visible || true)
    useEffect(() => {
        if (closable) {
            window.addEventListener('keydown', handleKeyPress)
            return (() => { window.removeEventListener('keydown', handleKeyPress) })
        }

    })

    const onWrapperClick = useCallback(() => {
        
        if (closable) {
            onClose()
        }
    }, [onClose, closable])
    const onContentClick = useCallback((e) => {
        e.stopPropagation()
    }, [])

    return (
        <div>
            {props.visible ?
                <ModalWrapper background={props.background} onClick={onWrapperClick}>
                    <Content onClick={onContentClick}>
                        {props.children}
                    </Content>
                    <BottomText>{props.info}</BottomText>
                </ModalWrapper> : null}
        </div>
    )
}