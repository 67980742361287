import React, { useMemo } from 'react'
import styled from 'styled-components'
import { matiere } from '../../services/students'
import { TabNotesLine } from './TabNotesLine'

const TableWrapper = styled.table`
    margin: 8px 0;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid grey;
    padding: 10px;
    

    &, tr, td {
        border: 1px solid grey;
        padding: 10px;
    }
`

export const TabNotesLines = props => {
    const periode = props.periode
    const lines = useMemo(() => {
        if (periode) {
            return periode.matieres.map((m) => {
                const matiereG = new matiere(m)
                let key = matiereG.codeMatiere
                if (key === "") {
                    key = matiereG.discipline
                }
                return <TabNotesLine key={key} matiere={matiereG} />
            })
        }
    }, [periode])

    return (<TableWrapper>
        <thead>
            <tr>
                <th>Disciplines</th>
                <th>Evaluations</th>
            </tr>
        </thead>
        <tbody>
            {lines}
        </tbody>
    </TableWrapper>)
}
