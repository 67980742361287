import React, { useMemo } from 'react'
import { usePeriode } from '../../services/periodes/periodes'
import styled from 'styled-components'


const MoyenneWrapper = styled.div`
    border: 1px solid grey;
    margin: 16px 0px;
    padding: 10px;
    width: fit-content;
`

const MoyenneP = styled.p`
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    margin: 10px 0;
`

const Moyenne = () => {
    const periode = usePeriode()
    const moyenne = useMemo(() => {
        if (periode) {
       return(periode.moyenne())
        }
    }, [periode])
    let message = "Moyenne: " + (isNaN(moyenne) ? "-" : moyenne.toFixed(2))
    return(
        <MoyenneWrapper>
            <MoyenneP>{message}</MoyenneP>
        </MoyenneWrapper>
    )
}







export default Moyenne