import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';


const NoteWrapper = styled(Link)`
    padding: 0px 0px 0px 20px;
    text-decoration: none;
    color: black;

    &:hover {
        text-decoration: underline;
    }

    & .valeur {
        size: inherit;
    }

    &.nonSignificatif {
        opacity: 0.4;
    }

     & .coef {
        position: relative;
        vertical-align: baseline;
        font-size: 75%;
        top: -.3em;
        margin-left: 2px;
    }

    &.blue {
        color: blue;
    }
    &.red {
        color: red;
    }
`


export const Note = ({ index, note, onNoteClick = function () { } }) => {

    let params = useParams()
    let colorClass = note.matiere.periode.slave ? "red" : ""
    let nonSignificatifClass = note.nonSignificatif ? "nonSignificatif" : ""
    let simulatedClass = note.simulated ? "blue" : ""

    return (
        <NoteWrapper to={`/${params.idStudent}/${note && note.matiere ? note.matiere.periode.id : params.periodeId}/info/${note.matiere.codeMatiere}/${note.getId}`} className={['note', colorClass, nonSignificatifClass, simulatedClass].join(' ')}>
            {note.nonSignificatif ? "(" : null}
            <span className="valeur">{String(note.valeur).replace(".", ",")}{note.noteSur !== 20 ? "/" + note.noteSur : ""}</span>
            {note.coef === 1 ? null : <span className="coef">({note.coef})</span>}
            {note.nonSignificatif ? ")" : null}
        </NoteWrapper>
    );
}