import React from 'react';
import {Note} from './Note'
import styled from 'styled-components';

function compare(a, b) {
    if (a.date < b.date) {
        return -1
    }
    else if (a.date > b.date) {
        return 1
    }
    else  {
        return 0
    }
}

const NotesUl = styled.ul`
    display: flex;
    padding: 0;
    margin: 0;

    & li {
        text-decoration: none;
        list-style: none;
    }
`

export function NoteList(props) {
    let list = props.list;
    list.sort(compare)
    list = list.map((x, index) => <li key={index}><Note index={index} note={x} /></li>);
    return (
        <NotesUl>
            {list}
        </NotesUl>
    );

}