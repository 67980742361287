import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import login from '../../services/session/login'
import { Modal } from '../Modals/Modal'


const StyledTitle = styled.h1`
    font-size: 3em;
    font-weight: bold;
`

export const LoginDialog = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [disabled, setDisabled] = useState(false)
    const onSubmit = props.onSubmit


    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setDisabled(true)
        login(username, password)
            .then((d) => {
                setDisabled(false)
                switch (d.code) {
                    case 505:
                        setMessage("Identifiant ou mot de passe invalide")
                        break;
                    case 500:
                        setMessage("Internal servor error")
                        break;
                    case 200:
                        onSubmit(d)
                        break;
                    default:
                        setMessage("Error")
                        break;  
                }

            })

    }, [username, password, onSubmit])
    const handleUsernameChange = useCallback((e) => {
        setUsername(e.target.value)
    }, [setUsername])

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value)
    }, [setPassword])


    return (
        <Modal closable={false} visible={props.visible} background={'#FFFFFF'}>
                <aside className="dialog">
                    <form className="dialog-content" onSubmit={handleSubmit}>
                        <StyledTitle>Connexion</StyledTitle>
                        <p className="message">{message}</p>
                        <input type="text" name="username" id="username" placeholder="nom d'utilisateur" autoComplete="username" value={username} onChange={handleUsernameChange}></input><br />
                        <input type="password" name="password" id="password" placeholder="Mot de passe" autoComplete="current-password" value={password} onChange={handlePasswordChange}></input><br />
                        <input type="submit" id="connect" value="Se connecter" disabled={disabled}></input>
                        <p>contact@neptunium.fr</p>
                    </form>
                </aside>
        </Modal>)


}


export default LoginDialog