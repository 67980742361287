import React from 'react';
import styled from 'styled-components';



const MatiereWrapper = styled.div`
    padding: 0;
    margin: 0;

    & p {
        padding: 0;
        margin: 0;
    }
`

export function MatiereElem(props) {
    let moyenne = props.matiere.moyenne().toFixed(2)
    if (isNaN(moyenne)) {
        moyenne = "-"
    }
    return (
        <MatiereWrapper className="matiere">
            <p>
                {props.matiere.discipline} ({moyenne.replace(".",",")})
            </p>

            {props.matiere.option !== 0 ? <p>
                option
            </p> : null}
            <p className="rang">
                rang: {props.matiere.rang}
            </p>
        </MatiereWrapper>
    );
}