

export class students {
    constructor(s, setter) {
        this.s = s
        this.set = setter
    }
    setSetter = (setter) => {
        this.set = setter
    }

    update = () => {
        this.set({ ...this.s })
    }
    addStudent = (s) => {
        this.s.students.push(s)
        this.update()
    }

    getStudent = (idStudent) => {
        let s = this.s.students.find(student => student.idStudent === Number(idStudent))
        if (s) {
            return new student(s)
        }
    }
    get students() {
        return this.s.students
    }

    static createStudents = () => {
        return { students: [] }
    }
}

export class student {
    constructor(p) {
        this.p = p
        this.update = p.students.update
    }
    addPeriode = (periode) => {
        this.p.periodes.push(periode)
        this.update()
    }
    get idStudent() {
        return this.p.idStudent
    }
    get periodes() {
        return this.p.periodes
    }

    getPeriode = (idPeriode) => {
        let p = this.p.periodes.find(periode => periode.idPeriode === idPeriode)
        if (p) {
            return new periode(p)
        }
    }


    static createStudent = (idStudent, students) => {
        const p = { idStudent: idStudent, students: students, periodes: [] }
        students.addStudent(p)
        return p
    }

}



export class periode {
    constructor(p) {
        this.p = p
        this.update = p.student.update
    }
    addMatiere = (matiere) => {
        this.p.matieres.push(matiere)
        this.update()
    }
    getMatiere = codeMatiere => {
        const m = this.p.matieres.find(m => m.codeMatiere === codeMatiere)
        if (m) {
            return new matiere(m)
        }
    }

    getMatieres = () => this.p.matieres

    linkTo = (p) => {
        if (p) {
            p.linkedTo.push(this)
            this.p.slave = true
        }
    }

    moyenne = () => {
        let m = 0
        let c = 0
        let matieres = this.getMatieres()
        for (let i = 0; i < matieres.length; i++) {
            let moyenne = new matiere(matieres[i]).moyenne()
            if (matieres[i].option !== 0) {
                if (!isNaN(moyenne) && moyenne > matieres[i].option) {
                    m += moyenne - matieres[i].option
                }
            } else if (!isNaN(moyenne)) {
                m += moyenne * matieres[i].coef
                c += matieres[i].coef
            }
        }
        return m / c
    }
    get id() {
        return this.p.idPeriode
    }
    get name() {
        return this.p.name
    }
    get linkedTo() {
        return this.p.linkedTo
    }
    get slave() {
        return this.p.slave
    }
    get matieres() {
        return this.p.matieres
    }

    static createPeriode = (idPeriode, name, student) => {
        const p = { idPeriode: idPeriode, name: name, matieres: [], student: student, linkedTo: [], slave: false }
        student.addPeriode(p)
        return p
    }
}


export class matiere {
    constructor(m) {
        this.m = m
        this.update = m.periode.update
    }
    addNote = (note) => {
        this.m.notes.push(note)
        this.update()
    }


    getNotes = () => {
        let lNotes = this.notes
        this.periode.linkedTo.forEach(p => {
            const m = p.getMatiere(this.codeMatiere)
            if (m) {
                lNotes = lNotes.concat(m.notes)
            }
        });
        return lNotes.map(n => new note(n))
    }

    moyenne = () => {
        let m = 0
        let c = 0;
        let lnotes = this.getNotes()
        for (let i = 0; i < lnotes.length; i++) {
            if (!lnotes[i].nonSignificatif && lnotes[i].noteSur !== 0 && !isNaN(lnotes[i].valeur)) {
                m += (lnotes[i].valeur / lnotes[i].noteSur) * lnotes[i].coef
                c += lnotes[i].coef
            }
        }
        return (m / c) * 20
    }

    moyenneClasse = () => {
        let m = 0
        let c = 0;
        let lnotes = this.getNotes()
        for (let i = 0; i < lnotes.length; i++) {
            if (!lnotes[i].nonSignificatif && lnotes[i].noteSur !== 0 && !isNaN(lnotes[i].moyenneClasse)) {
                m += (lnotes[i].moyenneClasse / lnotes[i].noteSur) * lnotes[i].coef
                c += lnotes[i].coef
            }
        }
        return (m / c) * 20
    }

    deleteNote = (note) => {
        let notesF = []
        this.notes.forEach(n => {
            if (n !== note.n) {
                notesF.push(n)
            }
        });
        this.m.notes = notesF
        this.update()
    }

    getNoteId(note) {
        for (let i = 0; i < this.notes.length; i++) {
            if (this.notes[i] === note.n) {
                return i
            }
        }
    }


    get codeMatiere() {
        return this.m.codeMatiere
    }

    get discipline() {
        return this.m.discipline
    }

    get coef() {
        return this.m.coef
    }

    get option() {
        return this.m.option
    }

    get rang() {
        return this.m.rang
    }
    get periode() {
        return this.m.periode
    }
    get notes() {
        return this.m.notes
    }



    static createMatiere = (codeMatiere, discipline, coef, option, rang, periode) => {
        const m = { codeMatiere: codeMatiere, discipline: discipline, coef: coef, option: option, rang: rang, periode: periode, notes: [] }
        periode.addMatiere(m)
        return m
    }

}



export class note {
    constructor(n) {
        this.n = n

    }

    get devoir() {
        return this.n.devoir
    }
    get coef() {
        return this.n.coef
    }
    get noteSur() {
        return this.n.noteSur
    }
    get valeur() {
        return this.n.valeur
    }
    get min() {
        return this.n.min
    }
    get max() {
        return this.n.max
    }
    get moyenneClasse() {
        return this.n.moyenneClasse
    }
    get nonSignificatif() {
        return this.n.nonSignificatif
    }
    get date() {
        return this.n.date
    }
    get simulated() {
        return this.n.simulated
    }
    get matiere() {
        return this.n.matiere
    }
    get getId() {
        return this.matiere.getNoteId(this)
    }
    setCoef = (coef) => {
        this.n.coef = coef
        this.n.simulated = true
        this.matiere.update()
    }
    setValeur = (valeur) => {
        this.n.valeur = valeur
        this.n.simulated = true
        this.matiere.update()
    }
    delete = () => {
        this.matiere.deleteNote(this)
    }
    static createNote = (devoir, coef, noteSur, valeur, moyenneClasse, min, max, nonSignificatif, matiere, date = new Date(), simulated = false) => {
        const n = { devoir: devoir, coef: coef, noteSur: noteSur, valeur: valeur, moyenneClasse: moyenneClasse, min: min, max: max, nonSignificatif: nonSignificatif, matiere: matiere, date: date, simulated: simulated }
        n.matiere.addNote(n)
        return (n)
    }


}