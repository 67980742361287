import React, { useCallback, useEffect, useState } from 'react';
import { usePeriode } from '../../services/periodes/periodes';
import { MobileTabNotesLines } from './MobileTabNotesLines';
import { TabNotesLines } from './TabNotesLines';



export const TabNotes = (props) => {
    const periode = usePeriode()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500)


    const resize = useCallback(() => {
        setIsMobile(window.innerWidth <= 500)
    }, [setIsMobile])

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize)
    }, [resize])

    

    return (
        <div>
            {!isMobile ?                        
                    <TabNotesLines periode={periode}/> : <MobileTabNotesLines periode={periode} />
                }
        </div>
    );
}