import { SERVER_URL } from '../constants'

export const login = (username, password) => {

    return new Promise((resolve, reject) => {
        const params = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: username, password: password })
        }
        fetch(`${SERVER_URL}login`, params)
            .then(d => d.json())
            .then((data) => {
                if (data.code === 200) {
                    accountProcessing({ code: data.code, token: data.token, accounts: data.data.accounts }, resolve)
                }
                else {
                    resolve({ code: data.code })
                }
            })
            .catch((e) => reject({ code: 404, error: e.message }))  //clear error test
    })
}

function accountProcessing(data, resolve) {
    let account = data.accounts[0]
    switch (account.typeCompte) {
        case "E":
            studentAccount(data.token, account, resolve)
            break;
        case "1":
            parentsAccount(data.token, account, resolve)
            break;
        case "2":
            parentsAccount(data.token, account, resolve)
            break;
        case "3":
            parentsAccount(data.token, account, resolve)
            break;
        default:
            resolve({ code: 500 })
    }
}

function studentAccount(token, account, resolve) {
    let eleves = [{ id: account.id, avatarSRC: account.profile.photo, firstname: account.prenom, lastname: account.nom }]
    resolve({ code: 200, token: token, eleves: eleves })
}

function parentsAccount(token, account, resolve) {
    let eleves = []
    account.profile.eleves.map((eleve) => eleves.push({ id: eleve.id, avatarSRC: eleve.photo, firstname: eleve.prenom, lastname: eleve.nom }))
    resolve({ code: 200, token: token, eleves: eleves })

}



export default login