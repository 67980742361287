import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { note, periode as GlobalPeriode, matiere as gMatiere } from '../../services/students'
import { usePeriode, useStudent } from '../../services/periodes/periodes'
import { Modal } from './Modal'


export const NoteSimulationModal = props => {
    const student = useStudent()
    const activePeriode = usePeriode()
    // const periodes = []
    // const activePeriode = {matieres: [{}], getMatieres: () => []}
    const history = useHistory()

    const periodes = useMemo(() => {
        if (student) {
            return student.periodes.map(p => new GlobalPeriode(p))
        }
        else {
            return []
        }
    }, [student])

    const periodesOptions = useMemo(() => {
        if (periodes.length >= 1) {
            return (periodes.map(p => <option key={p.id} value={p.id}>{p.name}</option>))
        }
    }, [periodes])

    const [periode, setPeriode] = useState(activePeriode ? activePeriode : {})

    useEffect(() => {
        if (activePeriode) {
            setPeriode(activePeriode)
        }
    }, [activePeriode])

    const periodeChange = useCallback(e => {
        setPeriode(student.getPeriode(e.target.value))
    }, [student])

    const matieresOptions = useMemo(() => {
        if (periode && periode.matieres) {
            return (periode.matieres.map(m => <option key={m.codeMatiere} value={m.codeMatiere}>{m.discipline}</option>))
        }
    }, [periode])

    const [matiere, setMatiere] = useState({})

    useEffect(() => {
        if (periode && periode.matieres) {
            setMatiere(new gMatiere(periode.matieres[0]))
        }
    }, [periode])
    const matiereChange = useCallback(e => {
        setMatiere(periode.getMatiere(e.target.value))
    }, [periode])

    const [value, setValue] = useState(null)

    const displayValue = useMemo(() => {
        if (!value) {
            return ('')
        }
        else {
            return String(value).replace('.', ',')
        }
    }, [value])

    const changeValue = useCallback((e) => {
        let v = e.target.value.replace(',', '.')
        setValue(v)

    }, [])

    const [coef, setCoef] = useState(null)

    const displayCoef = useMemo(() => {
        if (!coef) {
            return ('')
        }
        else {
            return String(coef).replace('.', ',')
        }
    }, [coef])

    const changeCoef = useCallback((e) => {
        let v = e.target.value.replace(',', '.')
        setCoef(v)

    }, [])



    const onSubmit = (e) => {
        e.preventDefault()
        const v = Number(value)
        const c = Number(coef)

        if (v >= 0 && v <= 20 && c > 0) {
            note.createNote("simulation", c, 20, v, 0, 0, 0, false, matiere, new Date(), true)   
            onClose()
        }
    }

    const onClose = useCallback(() => {
        history.push('../')
    }, [history])


    return (
        <Modal visible={props.visible} onClose={onClose}>
            <form className="dialog-content" onSubmit={onSubmit}>
                <h1>simulation notes</h1>
                <p>{null}</p>
                <select value={periode.id} onChange={periodeChange}>
                    {periodesOptions}
                </select>
                <select value={matiere.codeMatiere} onChange={matiereChange}>
                    {matieresOptions}
                </select>
                <input type="text" name="valeur" id="valeur" placeholder="20" value={displayValue} onChange={changeValue}></input><br />
                <input type="text" name="coef" id="coef" placeholder="1" value={displayCoef} onChange={changeCoef}></input><br />
                <input type="submit" id="connect" value="valider"></input>
            </form>
        </Modal>

    )



}

export default NoteSimulationModal