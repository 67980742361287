import React from 'react'
import {SERVER_URL} from '../../services/constants'
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

const UserInfoWrapper = styled(NavLink)`
    display: flex;
    justify-content: space-around;
    border: 1px solid grey;
    padding: 8px 15px;
    width: fit-content;
    cursor: pointer;
    margin-left: 10px;
    opacity: 0.5;

    &:hover p {
        text-decoration: underline;
    }

    &.selected {
        opacity: 1;
    }

    &:visited {
        all: inherit;
    }

    &:link {
        text-decoration: none;
    }
`

const Avatar = styled.img`
    margin: 5px;
    height: 80px;
    width: 80px;
    object-fit: none;
`

const NameWrapper = styled.div `
    margin: 5px;
    & p {
        margin: 0px;
        color: black;
    }
    

    
`


export const UserInfo = (props) => {
    const { url } = useRouteMatch()

    return(
        <UserInfoWrapper to={`${url}${props.id}`} activeClassName="selected">
            <Avatar className="userInfo-avatar" src={SERVER_URL + 'avatar?url=https:' + props.avatarSRC} alt="avatar"/>
            <NameWrapper>
                <p>{props.firstname}</p>
                <p>{props.lastname}</p>
            </NameWrapper>
        </UserInfoWrapper>
    )
}

export default UserInfo