import React, { useMemo } from 'react'
import UserInfo from '../../components/UserInfo'
import './Header.css'
import { useSession } from '../../services/session'
import styled from 'styled-components'
import { StyledA } from '../../components/styled/StyledA'




const LogoutWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const Logout = styled(StyledA)`
    margin: 5px;
    color: grey;
    font-size: 0.8em;
`


export const Header = (props) => {
    const session = useSession()



    const usersInfos = useMemo(() => {
        if (session.token) {
            return (session.eleves.map((eleve) => <UserInfo firstname={eleve.firstname} lastname={eleve.lastname} avatarSRC={eleve.avatarSRC} id={eleve.id} key={eleve.id} />))

        }
    }, [session])

    return (
        <div>
            <div className="header-parent">
                <h1 className="header-h1">ECOLEDIRECTE</h1>
                <div className="header-userlist">
                    {usersInfos}
                </div>
            </div>
            <LogoutWrapper><Logout onClick={session.clear} href="#">Se déconnecter</Logout></LogoutWrapper>
        </div>

    )

}

export default Header