import React from 'react';
import {MatiereElem} from './MatiereElem'
import {NoteList} from './NoteList'
import styled from 'styled-components';


const TabMatieres = styled.td`
    width: 30%;
`

const TabNotes = styled.td`
    width: max-content;
`


export function TabNotesLine(props) {
    var matiere = props.matiere
    return (
        <tr>
            <TabMatieres>
                <MatiereElem matiere={matiere} />
            </TabMatieres>
            <TabNotes>
                <NoteList list={matiere.getNotes()} />
            </TabNotes>
        </tr>
    );
}