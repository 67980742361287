import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePeriode } from '../../services/periodes/periodes'
import { CenterC, CenterE } from '../layout/Center'
import { DetailP } from '../styled/DetailP'
import { StyledLink } from '../styled/StyledLink'
import { Modal } from './Modal'



export const NoteInfo = props => {
    const history = useHistory()
    const periode = usePeriode()

    const params = useParams()

    const onClose = useCallback(() => {
        history.push('../../')
    }, [history])


    const note = useMemo(() => {
        if (periode) {
            const m = periode.getMatiere(params.codeMatiere)
            if (m) {
                const noteId = params.noteId
                if (!isNaN(noteId) && noteId >= 0 && noteId < m.notes.length) {
                    return m.notes[noteId]
                }
                else {
                    onClose()
                }
            }
            else {
                onClose()
            }
        }
        return {}
    }, [periode, onClose, params])

    return (
        <Modal visible={true} onClose={onClose}>
            <p>{note.devoir}</p>
            <p>{note.valeur}</p>
            <p>moyenne de classe: {note.moyenneClasse} (min: {note.min}, max: {note.max})</p>
            <hr></hr>
            <DetailP>simulation</DetailP>
            <CenterC>
                <CenterE>
                    <StyledLink to={`/${params.idStudent}/${params.periodeId}/edit/${params.codeMatiere}/${params.noteId}`}>editer la note</StyledLink>
                </CenterE>

                <CenterE>
                    <StyledLink to={`/${params.idStudent}/${params.periodeId}/delete/${params.codeMatiere}/${params.noteId}`}>supprimer la note</StyledLink>
                </CenterE>
            </CenterC>
        </Modal>
    )
}