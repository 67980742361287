import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { SessionProvider } from './services/session';
import { AppRouter } from './AppRouter';







const Index = props => {
    return(
        <SessionProvider>
                <div>
                    <AppRouter/>
                </div>
        </SessionProvider>
    )
}


ReactDOM.render(< Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();